var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"text-center"},[(_vm.setTimeoutLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(!_vm.setTimeoutLoading)?_c('v-card',{attrs:{"elevation":"0","min-width":"350","width":"100%"}},[_c('v-card-text',{staticClass:"text-subtitle-1"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("New Entry")])]),_c('div',{staticClass:"text-right"},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Today Date:")]),_vm._v(" "+_vm._s(_vm.getTodayDate())+" ")])])])]),_c('v-card-text',{staticClass:"d-flex flex-wrap justify-space-between py-0"},[_c('v-slide-group',{staticClass:"py-1"},[_c('v-slide-item',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNew),expression:"isNew"}],staticClass:"warning ml-2",attrs:{"rounded":"","depressed":""},on:{"click":_vm.clearEntry}},[_c('i',{staticClass:"fas fa-eraser mr-2"}),_vm._v(" Clear ")])],1),_c('v-slide-item',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNew),expression:"isNew"}],staticClass:"primary ml-2",attrs:{"rounded":"","depressed":"","loading":_vm.isLoading,"disabled":!_vm.isLoggedIn || !_vm.isDirty},on:{"click":_vm.addEntry}},[_c('i',{staticClass:"fas fa-save mr-2"}),_vm._v(" Submit ")])],1)],1)],1),_c('v-card-text',_vm._l((_vm.rows),function(row,idxI){return _c('v-row',{key:`row-${idxI}`},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(row.label != ''),expression:"row.label != ''"}],staticClass:"font-weight-bold headline black--text"},[_vm._v(" "+_vm._s(row.label)+" ")]),_vm._l((row.cols),function(col,idxJ){return _c('v-col',{key:`col-${idxJ}`},[(col.label != '')?_c('p',{staticClass:"font-weight-bold subtitle-1 black--text text-start"},[_vm._v(" "+_vm._s(col.label)+" ")]):(
							_vm.fieldToField[col.field]?.type === 'singleLine'
						)?_c('v-text-field',{attrs:{"label":_vm.fieldToField[col.field]?.displayName},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, $$v)},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type ===
							'currencyInDollar'
						)?_c('vuetify-money',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"options":_vm.fieldToField[col.field].options},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, _vm._n($$v))},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type === 'weightInLb'
						)?_c('vuetify-money',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"options":_vm.fieldToField[col.field].options},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, _vm._n($$v))},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type === 'weightInKg'
						)?_c('vuetify-money',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"options":_vm.fieldToField[col.field].options},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, _vm._n($$v))},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type === 'number'
						)?_c('v-text-field',{attrs:{"label":_vm.fieldToField[col.field]?.displayName},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, _vm._n($$v))},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type ===
							'multipleLines'
						)?_c('v-textarea',{attrs:{"label":_vm.fieldToField[col.field]?.displayName},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, $$v)},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type === 'singleSelect'
						)?_c('v-autocomplete',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"items":_vm.fieldToChoices[col.field],"item-text":"displayName","return-object":"","small-chips":"","deletable-chips":""},on:{"change":function($event){return _vm.automate(col.field)}},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, $$v)},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type ===
							'multipleSelect'
						)?_c('v-autocomplete',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"items":_vm.fieldToChoices[col.field],"item-text":"displayName","return-object":"","multiple":"","small-chips":"","deletable-chips":""},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, $$v)},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type === 'singleUser'
						)?_c('v-autocomplete',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"return-object":"","items":_vm.users,"item-text":_vm.getFullName},on:{"change":function($event){return _vm.automate(col.field)}},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, $$v)},expression:"entry[col.field]"}}):(
							_vm.fieldToField[col.field]?.type ===
							'multipleUsers'
						)?_c('v-autocomplete',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"return-object":"","items":_vm.users,"item-text":_vm.getFullName,"deletable-chipsoutlined":"","multiple":"","chips":"","deletable-chips":""},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, $$v)},expression:"entry[col.field]"}}):(_vm.fieldToField[col.field]?.type === 'date')?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.entry[col.field]),"label":_vm.fieldToField[col.field]?.displayName,"persistent-hint":"","prepend-icon":"mdi-calendar"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePicker[col.field]),callback:function ($$v) {_vm.$set(_vm.datePicker, col.field, $$v)},expression:"datePicker[col.field]"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.datePicker[col.field] = false}},model:{value:(_vm.entry[col.field]),callback:function ($$v) {_vm.$set(_vm.entry, col.field, $$v)},expression:"entry[col.field]"}})],1):(
							_vm.fieldToField[col.field]?.type === 'list'
						)?_c('div',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"font-weight-bold subtitle-1 black--text"},[_vm._v(" "+_vm._s(_vm.fieldToField[col.field]?.displayName)+" ")])])],1),_vm._l((_vm.entry[col.field]),function(lRow,lIdxI){return _c('v-row',{key:`lRow-${lIdxI}`},[_vm._l((_vm.fieldToField[
									col.field
								].listFields),function(field,lIdxJ){return _c('v-col',{key:`lCol-${lIdxJ}`},[(field?.type === 'singleLine')?_c('v-text-field',{attrs:{"label":field?.displayName},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(
										field?.type === 'currencyInDollar'
									)?_c('vuetify-money',{attrs:{"prefix":"$","label":field?.displayName,"options":field.options},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, _vm._n($$v))},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(field?.type === 'number')?_c('v-text-field',{attrs:{"label":field?.displayName},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, _vm._n($$v))},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(
										field?.typee === 'weightInLb'
									)?_c('vuetify-money',{attrs:{"suffix":"Lbs","label":field?.displayName,"options":field.options},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, _vm._n($$v))},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(field?.type === 'weightInKg')?_c('vuetify-money',{attrs:{"suffix":"Kg","label":field?.displayName,"options":field.options},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, _vm._n($$v))},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(
										field?.type === 'multipleLines'
									)?_c('v-textarea',{attrs:{"label":field?.displayName},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(
										field?.type === 'singleSelect'
									)?_c('v-autocomplete',{attrs:{"label":field?.displayName,"items":_vm.fieldToChoices[field.value],"item-text":"displayName","return-object":"","small-chips":"","deletable-chips":""},on:{"change":function($event){return _vm.automate(col.field)}},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(
										field?.type === 'multipleSelect'
									)?_c('v-autocomplete',{attrs:{"label":field?.displayName,"items":_vm.fieldToChoices[field.value],"item-text":"displayName","return-object":"","multiple":"","small-chips":"","deletable-chips":""},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(
										_vm.fieldToField[col.field]?.type ===
										'singleUser'
									)?_c('v-autocomplete',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"return-object":"","items":_vm.users,"item-text":_vm.getFullName},on:{"change":function($event){return _vm.automate(col.field)}},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(
										_vm.fieldToField[col.field]?.type ===
										'multipleUsers'
									)?_c('v-autocomplete',{attrs:{"label":_vm.fieldToField[col.field]?.displayName,"return-object":"","items":_vm.users,"item-text":_vm.getFullName,"deletable-chipsoutlined":"","multiple":"","chips":"","deletable-chips":""},model:{value:(
										_vm.entry[col.field][lIdxI][field.value]
									),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], field.value, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][field.value]\n\t\t\t\t\t\t\t\t\t"}}):(field?.type === 'date')?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(
													_vm.entry[col.field][lIdxI][
														field.value
													]
												),"label":field?.displayName,"persistent-hint":"","prepend-icon":"mdi-calendar","autocomplete":"off"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(
										_vm.datePicker[
											`${field.value}-${lIdxI}`
										]
									),callback:function ($$v) {_vm.$set(_vm.datePicker, 
											`${field.value}-${lIdxI}`
										, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\tdatePicker[\n\t\t\t\t\t\t\t\t\t\t\t`${field.value}-${lIdxI}`\n\t\t\t\t\t\t\t\t\t\t]\n\t\t\t\t\t\t\t\t\t"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.datePicker[
												`${field.value}-${lIdxI}`
											] = false}},model:{value:(
											_vm.entry[col.field][lIdxI][
												field.value
											]
										),callback:function ($$v) {_vm.$set(_vm.entry[col.field][lIdxI], 
												field.value
											, $$v)},expression:"\n\t\t\t\t\t\t\t\t\t\t\tentry[col.field][lIdxI][\n\t\t\t\t\t\t\t\t\t\t\t\tfield.value\n\t\t\t\t\t\t\t\t\t\t\t]\n\t\t\t\t\t\t\t\t\t\t"}})],1):_vm._e()],1)}),_c('v-col',[_c('v-btn',{attrs:{"rounded":"","color":"error mt-2","depressed":""},on:{"click":function($event){return _vm.removeFromList(lIdxI, col.field)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])],1)],2)}),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"rounded":"","color":"primary ml-2","depressed":""},on:{"click":function($event){return _vm.addRowIntoList(col.field)}}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(" Add ")])],1)],1)],2):_vm._e()],1)})],2)}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }