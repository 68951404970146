<template>
  <div class="d-flex justify-center mt-2">
    <v-card elevation="0" outlined min-width="350" width="60%">
      <v-card-title class="text-center">Welcome to Noteto</v-card-title>
      <v-card-text>
        <p>
          An intuitive and versatile record-keeping app that allows users to
          easily track and manage their data
        </p>
        <p>
          Whether tracking sales, inventory, or customer data, Noteto offers a
          simple and efficient way to organize and store important information.
          Users can easily search, view, and update their records at any time,
          making it easy to stay on top of changes and updates.
        </p>
        <p>
          With a user-friendly interface and flexible customization options,
          Noteto is the perfect solution for anyone looking to streamline their
          record-keeping process and stay organized in today's fast-paced world.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "HomePage",
};
</script>
